import React, { useRef } from 'react'
import styled, { keyframes, ThemeProvider } from 'styled-components'
import Button from "../Button";
import { dark } from "../../styles/Themes";

import img1 from '../../assets/diamond.jpg';
import img2 from '../../assets/airdrop.jpg';
import img3 from '../../assets/RaffleTicket.jpg';
import img4 from '../../assets/handshake.jpg';
import img5 from '../../assets/mailbox.jpg';
import img6 from '../../assets/Nfts/LoneWolf11.png';
import img7 from '../../assets/Nfts/LoneWolf12.jpg';
import img8 from '../../assets/Nfts/LoneWolf13.png';
import img9 from '../../assets/Nfts/LoneWolf14.jpg';
import img10 from '../../assets/Nfts/LoneWolf15.png';


const OutserSection = styled.section`
min-height: 10vh;
width: 100vw;
background-color: ${props => props.theme.text};
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;
padding-top: 6rem; /* Added padding */
`


const Section = styled.section`
min-height: 80vh;
width: 100vw;
background-color: ${props => props.theme.text};
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;

&>*:first-child{
  animation-duration: 20s;

  @media (max-width: 30em){
    animation-duration: 15s;

  }
}
&>*:last-child{
  animation-duration: 15s;
  @media (max-width: 30em){
    animation-duration: 10s;

  }
}
`
const move = keyframes`
0%{ transform: translateX(100%)   };
100%{ transform: translateX(-100%)   }

`

const Row = styled.div`
/* background-color: lightblue; */
white-space: nowrap;
box-sizing:content-box;
margin: 2rem 0;
display: flex;

animation: ${move}  linear infinite ${props => props.direction};


`
const ImgContainer = styled.div`
width: 18rem;
margin: 0 1rem;
background-color:${props => props.theme.body};

border-radius: 20px;
cursor: pointer;

@media (max-width: 48em){
  width: 12rem;
  }
  @media (max-width: 30em){
  width: 10rem;
  }

img{
  width: 100%;
  height: auto;
}
`

const Details = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
padding: 1rem 1rem;
background-color: ${props => props.theme.text};
border: 3px solid ${props => `rgba(${props.theme.bodyRgba},0.5)`};

border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;
box-sizing: border-box; 

span{
  font-size: ${props => props.theme.fontmd};
  color:${props => `rgba(${props.theme.bodyRgba},0.5)`};
  font-weight:600;
  line-height: 1.5rem;
  white-space: nowrap;
}

h1{
  font-size: ${props => props.theme.fontmd};
  color: ${props => props.theme.body};
  font-weight:600;
  max-width: 40em;
  overflow: hidden;

  @media (max-width: 30em){
    font-size: ${props => props.theme.fontxs};
    max-width: 10em;
    overflow:hidden;
    white-space: normal;

  }

}

`

const SubTextLight = styled.p`
  font-size: ${(props) => props.theme.fontmd};
  color: ${(props) => `rgba(${props.theme.bodyRgba},0.6)`};
  font-weight: 400;
  width: 50em;

  @media (max-width: 64em) {
    width: 100%;
    text-align: center;
    font-size: ${(props) => props.theme.fontsm};
  }
  @media (max-width: 40em) {
    font-size: ${(props) => props.theme.fontsm};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontxs};
  }
`;

const Title = styled.h2`
  color: ${props => props.theme.body};
  font-size: 3rem; /* Adjust font size as needed */
  margin-bottom: 1rem; /* Adjust margin bottom as needed */
  margin-top: 2rem; /* Added margin top */
`;

const ButtonContainer = styled.div`
  width: 80%;
  margin: 1rem auto;
  display: flex;
  justify-content: center;

  @media (max-width: 64em) {
    width: 100%;
    justify-content: center;

    button {
      margin: 0 auto;
    }
  }
`;

const NftItem = ({img, title="", description="", passRef}) => {

  let play = (e) => {
    passRef.current.style.animationPlayState = 'running';
  }
  let pause = (e) => {
    passRef.current.style.animationPlayState = 'paused';
  }

  return(
    <ImgContainer   onMouseOver={e => pause(e) }  onMouseOut={e => play(e) }  >
      <img width={500} height={400}  src={img} alt="Lone Wolf Tools" />
      <Details>
        <div>
          <span>{title}</span> <br />
          <h1>{description}</h1>
        </div>
      </Details>
    </ImgContainer>
  )
} 

const Showcase = () => {

  const Row1Ref = useRef(null);
  const Row2Ref = useRef(null);

  return(
     <OutserSection id="outer">
      <Title> Our Web3 Tools</Title>
      <SubTextLight>Whether you are a creator or a casual user on Solana, 
        we want you to be successful and safe on your journey. Our tools give you a great
        starting point! We will be adding more tools as we go!</SubTextLight>
        <ButtonContainer>
            <ThemeProvider theme={dark}>
              <Button text="VISIT TOOLS" link="https://tools.lone-wolf.xyz" newTab={true} />
            </ThemeProvider>
        </ButtonContainer>
        <Section id="showcase">
          <Row direction="none" ref={Row1Ref}>  
            <NftItem img={img1}  title={"Staking"} description={"Stake your NFT and Earn"} passRef = {Row1Ref} />
            <NftItem img={img2}  title={"Airdrop Tool"} description={"Airdrop SPL tokens or NFTs"} passRef = {Row1Ref} />
            <NftItem img={img3}  title={"Raffle"} description={"Supports SOL and SPL tokens"} passRef = {Row1Ref} />
            <NftItem img={img4}  title={"Wolf Pact"} description={"Peer-to-Peer trade tool"} passRef = {Row1Ref} />
            <NftItem img={img5}  title={"Bulk Send"} description={"Send NFTs in a single transaction"} passRef = {Row1Ref} />
          </Row>
          <Row direction="reverse" ref={Row2Ref}>
            <NftItem img={img6}  passRef = {Row2Ref} />
            <NftItem img={img7}  passRef = {Row2Ref} />
            <NftItem img={img8}  passRef = {Row2Ref} />
            <NftItem img={img9}  passRef = {Row2Ref} />
            <NftItem img={img10} passRef = {Row2Ref} />
          </Row>
        </Section>
      </OutserSection>
  )
}

export default Showcase